@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Nosifer&family=Nunito+Sans:opsz@6..12&family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Nosifer&family=Nunito+Sans:opsz@6..12&family=Plus+Jakarta+Sans&family=Press+Start+2P&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Outfit, system-ui, sans-serif;
    }
}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Outfit" !important;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}

.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.MuiDrawer-paper {
    width: 100% !important;
}

.material-icons.md-18 {
    font-size: 30px;
}

h1.MuiTypography-h1 {
    font-family: "Outfit", "Poppins", sans-serif;
}

h2.MuiTypography-h2 {
    font-family: "Outfit", "Avenir", "Poppins", sans-serif;
}

p.MuiTypography-body1 {
    font-family: "Outfit", "Avenir", "Montserrat", sans-serif !important;
}

p.MuiTypography-body2 {
    font-family: "Outfit", "Avenir-Light", "Montserrat", sans-serif !important;
}

p.MuiTypography-body3 {
    font-family: "Outfit", "Montserrat", sans-serif !important;
}

h5.MuiTypography-h5 {
    font-family: "Outfit", "Avenir", "Noto sans", sans-serif !important;
}

#my-div {
    width: 400px;
    height: 200px;
    overflow: hidden;
    position: relative;
}

#my-iframe {
    position: absolute;
    top: -200px;
    left: 100px;
    width: 1240px;
    height: 1200px;
}

.MuiTableRow-root:nth-child(odd) {
    background-color: #eef2f5;
}

/* .App {
  max-width: 400px;
  margin: 30px auto;
  /* text-align: center; */
/* } */
/* nav{
  padding: 5px;
}
nav ul {
  padding: 0;
}
nav ul li {
  display: inline-block;
  margin: 0 10px;
} */
/* .chapter-list {
  padding: 20px;
  margin-bottom: 20px;
}
.chapter-list ul{
  padding: 0;
  list-style-type: none;
}
.chapter-list li {
  padding: 10px;
  margin: 10px auto;
  border-radius: 10px;
} */
